<!-- Localized -->
<template>
    <div v-if="currentToolSelected && currentToolSelected !== 'search-engine-tool' && currentToolSelected !== 'other-tool'" id="search-queries" ref="search_queries" class="search-queries relative h-full  flex" @scroll="alignQueryLoader">
        <div class="bg-white self-start shadow-md border border-solid border-gray-200 sm:rounded-md relative overflow-hidden flex flex-col flex-grow" :class="{'cursor-not-allowed opacity-75 pointer-events-none': disabled}">
            <div class="search-queries-header flex px-2 py-2 justify-between bg-brand-area-color items-center sticky top-0">
                <div class="flex flex-col">
                    <div class="font-semibold text-gray-700 mt-3">{{ $t('components.searched_queries.search_queries') }}</div>
                </div>
                <div class="flex items-center mt-3">
                    <!-- <svg 
                        v-if="filterShow" 
                        @click="onFilterClick" v-tippy :content="$t('components.searched_queries.filters')" 
                        xmlns="http://www.w3.org/2000/svg" 
                        class="cursor-pointer outline-none mr-4" 
                        name="ungroupedComponent" 
                        width="24" 
                        height="16.799" 
                        viewBox="0 0 24 16.799"
                    >
                        <path id="filters-solid" d="M22.488,32a1.5,1.5,0,0,1,1.1,2.515l-5.6,6.091V46.4a1.2,1.2,0,0,1-1.92.96l-2.4-1.8a1.192,1.192,0,0,1-.48-.96V40.606l-5.6-6.091a1.434,1.434,0,0,1-.27-1.618A1.483,1.483,0,0,1,8.655,32Zm-21,2.4H6.142a2.711,2.711,0,0,0,.559.926l5.287,5.715v.66l-1.2,1.3V47.6a1.2,1.2,0,0,1-1.92.96l-2.4-1.8a1.192,1.192,0,0,1-.48-.96V43.006l-5.6-6.09a1.5,1.5,0,0,1,1.1-2.516Z" transform="translate(0.012 -32)" fill="#0d69d5" />
                    </svg> -->
                    <!-- <div class="bg-red-500 w-4 h-4 -ml-4 -mt-4 text-xs font-bold text-center text-white rounded-full p-0.5 mx-auto shadow" v-if="filterShow && toolFiltersCount">
                        !
                    </div> -->
                    <!-- <font-awesome-icon icon="check-circle" class="h-6 w-6 -ml-4 -mt-4 text-xl text-blue-600 font-bold text-center text-white rounded-full p-0.5 mx-auto shadow"> </font-awesome-icon> -->
                    <!-- <div class="px-1.5 py-0.5 -ml-6 -mt-6 rounded-full flex text-center items-center bg-red-500 text-sm cursor-pointer" v-if="filterShow"  :title="$t('components.searched_queries.filters_applied')">
                        <div class="mx-auto text-xs text-white">{{ toolFiltersCount }}</div>
                    </div> -->
                    <font-awesome-icon icon="sync" class="cursor-pointer hover:text-blue-400 hover:animate-spin outline-none" :class="{'animate-spin': queriesLoading}" v-tippy :content="$t('components.searched_queries.refresh_search_queries')" @click="handleRefreshQueries"> </font-awesome-icon>
                </div>
            </div>
            <div class="flex px-2 bg-brand-area-color items-center text-sm sticky top-0" 
                v-if="filteredQueries.length > 0 && filterShow"
            >
                <input type="checkbox" 
                    class="form-checkbox mr-2 h-4 w-4 text-brand cursor-pointer" 
                    @click="selectAllQueries(allQuerySelected)" 
                    v-if="filteredQueries.length > 0 && filterShow" 
                    :checked="allQuerySelected" 
                />

                <span> {{ allQuerySelected ? $t('components.searched_queries.deselect') : $t('components.searched_queries.select') }} All</span>
                <button @click="onRunQueries()" :disabled="!isEnabledApplyButton" :class="isEnabledApplyButton ? 'bg-brand cursor-pointer hover:bg-brand-hover': 'bg-gray-500 cursor-not-allowed'" class="border-none rounded p-1 mb-2 flex items-center ml-auto text-white">
                    {{ $t('components.searched_queries.view_results') }}
                </button>
            </div>
            <neo-layout :loading="isLoading" :show-loader="false">
            <div v-if="filteredQueries.length" class="border-0 border-solid border-gray-200 border-t border-b max-h-full scroll-bar overflow-y-auto h-full flex-grow">
                <div
                    class="relative flex items-center justify-between px-2 py-3 border-0 border-b border-solid border-gray-200 hover:bg-blue-50"
                    v-for="query in filteredQueries"
                    :key="query._id"
                    @click="handleClick(query)"
                    :class="{'bg-blue-100': selectedRow === query._id || (query.run_query === true && query.selected === true), 'line-through': query.monitoring_status === 'disabled' && $route.name === 'monitoring', 'cursor-not-allowed': disabled, 'cursor-pointer': !disabled}"
                >
                    <!-- Removed query keys name which are page_size and start -->

                    <div class="flex items-center align-middle" :style="getQueryStyle(query)">
                        <div class="flex items-center">
                            <input v-if="filterShow" type="checkbox" class="form-checkbox h-4 w-4 text-brand mr-2 cursor-pointer" @change="handleChange($event, query._id)" @click.stop :checked="query.run_query" />
                            <div v-if="query.monitoring_allowed">
                                <neo-toggle-switch :status="query.is_monitoring" :disabled="disabled" @click="handleMonitoringStatus(query)" :key="query._id" class="mx-auto p-1 mr-2" />
                                <span class="text-xs text-gray-500">{{ $t('components.searched_queries.monitor') }}</span>
                            </div>
                        </div>
                        <span class="w-full ml-2" :title="viewQuery(query)">
                            <div class="w-full flex justify-between">
                                <p class="line-clamp-1 text-ellipsis" style="max-width: 180px;">{{ viewQuery(query) }}</p>
                                <div v-if="getprebuildpermission">
                                    <span v-if="!savedQuery(query._id)" @click.stop="saveQuery(query)" :title="savedQuery(query._id)">
                                        
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <mask id="mask0_34_7205" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
                                                <rect width="16" height="16" fill="#D9D9D9"/>
                                            </mask>
                                            <g mask="url(#mask0_34_7205)">
                                                <path d="M3.33325 14V3.33333C3.33325 2.96667 3.46381 2.65278 3.72492 2.39167C3.98603 2.13056 4.29992 2 4.66659 2H11.3333C11.6999 2 12.0138 2.13056 12.2749 2.39167C12.536 2.65278 12.6666 2.96667 12.6666 3.33333V14L7.99992 12L3.33325 14ZM4.66659 11.9667L7.99992 10.5333L11.3333 11.9667V3.33333H4.66659V11.9667Z" fill="#0D69D5"/>
                                            </g>
                                        </svg>
                                    </span>
                                    <span v-else @click.stop="getSavedQuery(query._id)" :title="savedQuery(query._id)">
                                        
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <mask id="mask0_34_10761" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
                                                <rect width="16" height="16" fill="#D9D9D9"/>
                                            </mask>
                                            <g mask="url(#mask0_34_10761)">
                                                <path d="M7.29992 9.33333L10.5999 6.03333L9.64992 5.1L7.29992 7.45L6.34992 6.5L5.39992 7.45L7.29992 9.33333ZM3.33325 14V3.33333C3.33325 2.96667 3.46381 2.65278 3.72492 2.39167C3.98603 2.13056 4.29992 2 4.66659 2H11.3333C11.6999 2 12.0138 2.13056 12.2749 2.39167C12.536 2.65278 12.6666 2.96667 12.6666 3.33333V14L7.99992 12L3.33325 14Z" fill="#3E9F3C"/>
                                            </g>
                                        </svg>

                                    </span>
                                </div>
                            </div>
                                <!-- <span class=" truncate w-full ml-2" :title="viewQuery(query)"> -->
                            
                            <!-- tools to have saved count  -->
                            <div v-if="showResultsCount" class="flex pt-2 justify-between items-center w-full">
                                <div class="flex gap-3">
                                    <!-- <span v-tippy :content="$t('components.searched_queries.saved_results')" class="flex text-gray-400 items-center text-xs gap-1">
                                        <font-awesome-icon icon="save" class="text-xs text-blue-500" style="font-size: 10px"> </font-awesome-icon>{{ query.saved_results }}</span> -->
                                </div>
                                <div class="text-xs ml-auto text-gray-400 font-semibold">{{ $t('components.searched_queries.total', { value: query.total_results }) }}</div>
                            </div>
                        </span>
                        <div class="ml-auto mb-2" v-if="currentToolSelected == 'news-tool'">
                            <span class="relative inline-block">
                                <!-- <img class="cursor-pointer" :src="require('@/assets/icons/search-engines/analyser.svg')" /> -->
                            </span>
                        </div>
                    </div>

                    <!-- {{query.status}} -->
                    <div class="flex">
                        <div class="px-2 py-0.5 rounded-full bg-brand flex text-center items-center" v-if="query.events && query.is_monitoring && query?.monitoring_allowed"  :title="$t('components.searched_queries.monitoring_events')">
                            <div class="font-semibold text-xs text-white mx-auto">{{query.events}}</div>
                        </div>

                        <span v-if="$route.name !== 'monitoring' && $route.name !== 'monitoring-ro' && (query.status === 'PENDING' || query.status === 'RUNNING' || (query.status === 'DONE' && !query.visited))" class="flex mr-1">
                            <span class="rounded-full flex h-3 w-3 bg-blue-500" v-if="query.status === 'PENDING'"></span>
                            <!-- <svg v-else-if="query.status == 'DONE' && !query.visited" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                            </svg> -->
                            <!-- <span class="flex max-h-4 w-3" v-else-if="query.status === 'RUNNING'">
                                <neo-loader :loading="true"></neo-loader>
                            </span> -->
                            <span v-else></span>
                        </span>

                        <div class="flex -right-2 items-center monitoring-settings-panel pr-1" v-if="$route.name === 'monitoring'" @click="handleSettings($event)">
                            <span :name="query._id" @click="treeData = query">
                                <font-awesome-icon icon="ellipsis-v" class="p-2 h-4 box-size-initial text-gray-400 focus:outline-none" v-if="!query.frequency" v-tippy :content="`Monitoring Settings`"> </font-awesome-icon>
                                <!-- <span class="text-green-600" v-else v-tippy :content="`Frequency`"> {{ getFrequency(query.frequency) }} </span> -->
                                <!-- <div class="px-2 h-5 bg-brand rounded-full flex text-center items-center">
                                <div class="text-xs font-semibold text-white mx-auto" style="line-height: initial">{{ query.frequency }}</div>
                            </div> -->

                                <a class="p-2 text-xs font-semibold text-brand mx-auto no-underline hover:underline" v-else v-tippy :content="$t('components.searched_queries.frequency', {value: getFrequency(query.frequency)})">{{ query.frequency }} d </a>
                            </span>
                            <tippy :onHidden="hideOptionsBgLayer" :onShow="showOptionsBgLayer" :to="query._id" arrow arrowType="round" interactive theme="light monitoring" trigger="click" maxWidth="500" size="large">
                                <options-widget
                                    :ref="`options-widget-${query._id}`"
                                    :monitoring_data="{
                                        monitoring_status: query.monitoring_status === 'enabled',
                                        frequency: query.frequency,
                                    }"
                                    source="monitoring-query"
                                    :tool="currentToolSelected"
                                    @updateFrequency="updateFrequency(query, true)"
                                    @updateMonitoring="updateMonitoring"
                                >
                                </options-widget>
                            </tippy>
                            <div ref="optionsbgLayer" id="optionsbgLayer" @click="isOpen = false"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-base text-gray-500 px-2 h-full flex-grow" v-else-if="!isLoading">
                {{ $t('components.searched_queries.no_query_found') }}
            </div>
            </neo-layout>
            <span id="queries-loader" class="absolute bg-blue-100 px-4 py-2 max-h-20 rounded-lg text-center top-1/2 w-full" v-if="isLoading">
                <div class="spinner-4" style="margin-left: calc(50% - 15px)"></div>
                <span class="ml-2">{{ $t('components.searched_queries.please_wait_fetching_data') }}</span>
            </span>
        </div>
        <modal-content ref="save-prebuild-query" :max-width="500" name="prebuildquery" title="Save Prebuild Query"
            @close="closePrebuildQuery">
            <template #content>
                <div class="flex-1 overflow-hidden" >
                    <div class="flex flex-col flex-wrap gap-4">
                        <FormulateInput
                            class="w-full pb-2"
                            label="Query Name"
                            placeholder="Please enter query name"
                            type="text"
                            validation="bail|required|max:100|matches:/^(?!\s)(?!.*\s$)[A-Za-z0-9\s]+$/"
                            v-model="queryName"
                        />
                    </div>
                    <div class="flex flex-col flex-wrap gap-4">
                        <FormulateInput
                        class="w-full pb-2"
                        label="Query"
                        placeholder="Query"
                        type="text"
                        v-model="queryData"
                    />
                    </div>
                </div>
            </template>
            <template #footer>
                <button class="btn normal-case px-4 leading-tight h-auto min-h-0 py-2 gap-2 flex items-center focus:bg-blue-700 
                    rounded-xl btn-primary" @click="saveQueryData" v-if="savebutton"
                    :disabled="getDisableState" >{{ $t('pages.field_table.save') }}</button>
                <button class="btn normal-case px-4 leading-tight h-auto min-h-0 py-2 gap-2 flex items-center 
                    focus:bg-blue-700 rounded-xl btn-primary" @click="updateQueryData"
                    :disabled="getDisableState" v-if="!savebutton" >{{ $t('pages.bi_dashboard.update') }}</button>
            </template>
        </modal-content>
    </div>
</template>

<script>
// import NeoLoader from "@/components/loader";
import NeoLayout from "@shared/components/neo-layout";
import NeoToggleSwitch from "@shared/components/toggle-switch";
import optionsWidget from "@shared/saas-components/options-widget";
import {TippyComponent} from "vue-tippy";
import {mapMutations, mapActions, mapGetters} from "vuex";
import {cloneDeep} from "lodash";
import { checkPermission } from "@shared/utils/functions";
import tools from "@shared/tools/mixins/tools"
import modalContent from "@/components/modal-content";
import { PRE_BUILD_QUERY } from "@shared/utils/constants.js";
import { EventBus } from "@/main.js";

export default {
    name: "SearchQueries",
    mixins: [tools],
    data() {
        return {
            test: [],
            mouseOverMonitoring: false,
            componentKey: 0,
            queriesLoading: false,
            treeData: null,
            filterShowTool: ["company-name", "lexis-bridger-tool", "vlex-tool", "orbis-tool", "world-compliance-tool"],
            selectedQueries: [],
            queryData:'',
            queryName:'',
            osintRequestId: '',
            matchesavequery: false,
            savebutton: true,
        };
    },
    props: {
        currentToolSelected: String,
        queries: Array,
        input_value_list: Array,
        monitoring: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        toolMonitoring: {
            type: Boolean,
            default: false,
        },
        toolFiltersCount: {
            type: Number,
            default: 0,
        },
    },

    created() {
      EventBus.$on("onSelectAllQueries", () => {
        this.selectAllQueries(false);
      });
    },

    beforeDestroy() {
      EventBus.$off("onSelectAllQueries");
    },

    computed: {
        ...mapGetters([ "searchedQueries", "getNeoCaseId", "getAnalyzerOptions", "getUserFeatures", "getAutoAnalyze", "getSelectedToolQuery", "getUserFeatures", "queriesLoadingStore", "queryResultsLoading", "getCurrentApp"]),
        isLoading() {
            return this.queryResultsLoading || this.queriesLoadingStore || this.queriesLoading || this.disabled
        },
        showResultsCount() {
            return ["adverse-media-tool", "news-tool", "company-name", "offshoreleaks-tool", "lexis-bridger-tool", "world-compliance-tool", "vlex-tool", "orbis-tool"].includes(this.currentToolSelected);
        },

        monitoringAllowed() {
            if (this.currentToolSelected=='lexis-bridger-tool') {
                return this.getUserFeatures.includes("adverse_media_pep_sanctions__monitoring")
            }
            else if (this.currentToolSelected=='company-name') {
                return this.getUserFeatures.includes("corporate_record__monitoring")
            }
             else if (this.currentToolSelected=='orbis-tool') {
                return this.getUserFeatures.includes("orbis_record__monitoring")
            }
            else return false
        },
        filteredQueries() {
            let filtered = [...this.queries];
            const values = this.input_value_list?.map((n) => n.value);
            if (values?.length > 0) {
                filtered = filtered.filter((query) => {
                    if (this.currentToolSelected === "person-identifier-tool" && (Array.isArray(query?.query?.name) || Array.isArray(query?.query?.email) || Array.isArray(query?.query?.phone) || Array.isArray(query?.query?.locations) || Array.isArray(query?.query?.social_details) || Array.isArray(query?.query?.jobs) || Array.isArray(query?.query?.educations))) {
                        return this.viewQuery(query)
                            .split(",")
                            .some((n1) => values.includes(n1?.trim()));
                    } else return Object.values(query.query).find((n1) => values.includes(n1?.trim()));
                });
            }
            // if ((this.$route.name === "monitoring" || this.$route.name === "monitoring-ro") && !this.toolMonitoring) {
            if (this.$route.name === "monitoring" || this.$route.name === "monitoring-ro") {
                filtered = filtered.filter((query) => query.is_monitoring);
                return filtered
                    ?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
                    ?.sort((a) => {
                        if (a.monitoring_status === "enabled") return -1;
                        else return 1;
                    });
            }

            return filtered
                ?.filter((query) => {
                    if (query.tool === 'lexis-bridger-tool') {
                        if (query?.query?.entity_type === "Individual") return query?.query?.person_entity?.full_name?.trim() ;
                        else return query?.query?.company_entity?.name?.trim();
                    }
                    else return this.viewQuery(query).trim()
                })
                ?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        },
        selectedRow() {
            return this.getSelectedToolQuery?.query_id ?? this.$route?.query?.query_id;
        },
        tabName() {
            return this.$route.name;
        },
        filterShow() {
            return this.filterShowTool.includes(this.currentToolSelected);
        },
        allResultTools() {
            return ["company-name", "lexis-bridger-tool", "orbis-tool"].includes(this.currentToolSelected);
        },
        allQuerySelected() {
            return this.filteredQueries.every((query) => query.run_query);
        },
        isEnabledApplyButton() {
            return this.filteredQueries.some((query) => query.run_query);
        },
        getDisableState(){
            return (!this.queryName)
        },
        getprebuildpermission(){
            return checkPermission('prebuildQuery.save_pre_build_query') || false
        },
        isValidJson() {
            try {
                JSON.parse(this.queryData);
                return true; // Valid JSON
            } catch (e) {
                return false; // Invalid JSON
            }
        },

    },
    components: {
        // NeoLoader,
        NeoLayout,
        NeoToggleSwitch,
        "options-widget": optionsWidget,
        tippy: TippyComponent,
        modalContent,
    },
    mounted() {
        this.alignQueryLoader();
    },
    methods: {
        ...mapMutations(["SET_SELECTED_TOOL_QUERY", "SET_SEARCHED_QUERIES"]),
        ...mapActions(["getToolsStatus", "getQueryMonitoringEvents"]),
        alignQueryLoader() {
            let totalHeight = document.getElementById("search-queries")?.clientHeight;
            let position = document.getElementById("search-queries")?.scrollTop;
            let component = document.getElementById("queries-loader");
            if (component) component.style.top = position ? `calc(${totalHeight / 2}px - 10px + ${position}px)` : `calc(${totalHeight / 2}px - 10px)`;
        },
        handleClick(query, runQuery) {
            if (query) {
                let selectedQuery = {query_id: query._id, query_string: this.viewQuery(query), search_string: this.viewQuery(query), query};
                this.SET_SELECTED_TOOL_QUERY(selectedQuery);
                if (!runQuery) this.$emit("searchQueryClicked", query);
            } else {
                this.SET_SELECTED_TOOL_QUERY(null);
            }
        },
        getSourceString(payload) {
            return checkPermission("checkOutput.source_name") ? payload : "";
        },
        // TODO move all source list string generation logic to getSourceString + simplify it
        viewQuery({query}) {
            if (this.currentToolSelected === "adverse-media-tool") {
                const sourceString = this.getSourceString(` ( ${query.sources?.filter((el) => el !== "offshoreleaks")?.join(", ")} )`);
                return query?.name + sourceString;
            } else if (this.currentToolSelected === "lexis-bridger-tool") {
                if (query.entity_type === "Individual") return `${query?.person_entity?.full_name} (${query?.entity_type})`;
                else return `${query?.company_entity?.name} (${query?.entity_type})`;
            } 
            else if (this.currentToolSelected === "world-compliance-tool") {
                if (query.entry_type === "Individual") return `${query?.person_entity?.full_name} (${query?.entry_type})`;
                else return `${query?.company_entity?.company_name} (${query?.entry_type})`;
            } 
            else if (this.currentToolSelected === "offshoreleaks-tool") {
                const sourceString = this.getSourceString(` ( ${query.sources?.join(", ")} )`);
                return query?.name + sourceString;
            } else if (this.currentToolSelected === "news-tool") {
                let plain_query = query.query?.split(" near")[0];
                const sourceString = this.getSourceString(` ( ${query.sources?.join(", ")} )`);
                return plain_query + sourceString;
            } else if (this.currentToolSelected === "company-name") {
                if (query?.officer && query?.officer !='') {
                    if (query?.name) {
                        delete query['name']
                    }
                }

                let src = [];
                if (query?.sources && checkPermission("checkOutput.source_name")) {
                        src = query?.sources.map((source) => {
                            if(source === "db" && this.getUserFeatures.includes("db__corp_record") && !this.getUserFeatures.includes("dnb_reseller__corp_record"))
                                source = "d&b"
                            let name = source.toLowerCase()
                            if(source !== "offshoreleaks")
                            return name[0].toUpperCase() + name.slice(1);
                        });
                    }

                const resolvedName = query.name ?? query.officer ?? query.address;
                return query.sources?.length > 0 ? `${resolvedName} (${src?.join(", ")})` : `${resolvedName}`;
            }else if (this.currentToolSelected === "orbis-tool") {
                if (query?.officer && query?.officer !='') {
                    if (query?.name) {
                        delete query['name']
                    }
                }

                let src = [];
                if (query?.sources && checkPermission("checkOutput.source_name")) {
                        src = query?.sources.map((source) => {
                            if(source === "db" && this.getUserFeatures.includes("db__corp_record") && !this.getUserFeatures.includes("dnb_reseller__corp_record"))
                                source = "d&b"
                            let name = source.toLowerCase()
                            if(source !== "offshoreleaks")
                            return name[0].toUpperCase() + name.slice(1);
                        });
                    }

                const resolvedName = query.name ?? query.officer ?? query.address;
                return query.sources?.length > 0 ? `${resolvedName} (${src?.join(", ")})` : `${resolvedName}`;
            } else if (this.currentToolSelected === "court-records-tool") {
                let searchQueryString = "";
                // according to old tools search judy
                if (query?.search_query) {
                    return `${query?.search_query}`;
                    // according to new tool search judy or uni
                } else if (query?.case_search_list.length) {
                    const dropDownItems = ["condition_operator", "party_types", "partyTypes", "proximity", "representation", "scope", "search_type"];
                    query?.case_search_list.forEach((row) => {
                        for (const key in row) {
                            if (!dropDownItems.includes(key)) {
                                searchQueryString = searchQueryString + row[key] + (row[key].toString().split(" ").length > 1 ? "" : " ");
                                searchQueryString = searchQueryString + ", ";
                            }
                        }
                    });
                    searchQueryString = searchQueryString
                        .replaceAll(" OR ", ", ")
                        .replaceAll(" AND ", ", ")
                        .replaceAll(" NOT ", ", ")
                        .replace(/,\s*$/, "");
                    let names = [];
                    if (query?.sources && checkPermission("checkOutput.source_name")) {
                        names = query?.sources.map((src) => {
                            let name = src.toLowerCase();
                            return name[0].toUpperCase() + name.slice(1);
                        });
                    }
                    const sourceString = this.getSourceString(` ( ${names.join(", ") || " "} )`);
                    return searchQueryString + sourceString;
                } else {
                    return searchQueryString;
                }
            } else if (this.currentToolSelected === "gdc-person") {
                let searchQuery = [];
                if (query.identity.first_name || query.identity.middle_name || query.identity.last_name) {
                    searchQuery.push(`${query.identity.first_name + " " + query.identity.middle_name + " " + query.identity.last_name}`);
                }
                if (query.identity.dob) {
                    searchQuery.push(`${query.identity.dob}`);
                }
                if (query.identity?.nationalid?.length > 0) {
                    let ids = [];
                    query.identity.nationalid.forEach((el) => {
                        if (el.id) ids.push(el.id);
                    });
                    if (ids.length > 0) {
                        let nationalids = ids.join(",");
                        searchQuery.push(nationalids);
                    }
                }
                if (query.identity?.organization_name) {
                    searchQuery.push(`${query.identity.organization_name}`);
                }
                if (query.identity.business && query.identity.business.length > 0) {
                    let businessids = [];
                    query.identity.business.forEach((el) => {
                        if (el.id) businessids.push(el.id);
                    });
                    if (businessids.length > 0) {
                        let business_ids = businessids.join(",");
                        searchQuery.push(business_ids);
                    }
                }
                if (query.address.addressLine1 != "" || query.address.houseNumber != "" || query.address.countryCode != "") {
                    searchQuery.push(`${query.address.houseNumber + " " + query.address.addressLine1 + " " + query.address.countryCode}`);
                }
                if (query.address?.district && query.address?.district != "") {
                    searchQuery.push(`${query.address.district}`);
                }
                if (query.address.locality != "" || query.address.postalCode != "" || query.address.province != "") {
                    searchQuery.push(`${query.address.locality + " " + query.address.province + " " + query.address.postalCode}`);
                }
                if (query.email.full_email_address != "") {
                    searchQuery.push(`${query?.email.full_email_address}`);
                }
                if (query.phone.phone_number != "") {
                    searchQuery.push(`${query.phone.phone_number}`);
                }
                return searchQuery.join();
            } else if (this.currentToolSelected == "person-identifier-tool") {
                if (Array.isArray(query?.name) || Array.isArray(query?.email) || Array.isArray(query?.phone) || Array.isArray(query?.locations) || Array.isArray(query?.social_details) || Array.isArray(query?.jobs) || Array.isArray(query?.educations)) {
                    let queryView = [];
                    query?.name?.forEach((res) => {
                        let name = "";
                        if (res && (res.first_name || res.middle_name || res.last_name)) {
                            name = ((res.first_name || "") + " " + (res.middle_name || "") + " " + (res.last_name || "")).trim();
                        }
                        if (name) queryView.push(name);
                    });
                    query?.email?.forEach((res) => {
                        if (res && res.email) {
                            queryView.push(res.email);
                        }
                    });
                    query?.phone?.forEach((res) => {
                        if (res && res.phone) {
                            queryView.push(res.phone);
                        }
                    });
                    query?.location?.forEach((res) => {
                        if (res && (res.country || res.state || res.city)) {
                            queryView.push(res.country + " " + res.state + " " + res.city);
                        }
                    });
                    query?.social_details?.forEach((res) => {
                        if (res && res.social_profile) {
                            queryView.push(res.social_profile);
                        }
                        if (res && res.username) {
                            queryView.push(res.username);
                        }
                    });
                    query?.jobs?.forEach((res) => {
                        if (res && res.organization) {
                            queryView.push(res.organization);
                        }
                    });
                    query?.educations?.forEach((res) => {
                        if (res && res.school) {
                            queryView.push(res.school);
                        }
                    });
                    return queryView.join(", ");
                } else {
                    return Object.entries(query)
                        .map((n) => (n[0] != "page_size" && n[0] != "discover_email" && n[0] != "top_match" && n[0] != "start" ? n[1] : ""))
                        .filter((n) => n)
                        .join(", ");
                }
                
            } else if (this.currentToolSelected === "whatsapp-tool") {
                if (query.country_code) {
                    return "+" + query?.country_code + query?.number;
                } else {
                    return query?.number;
                }
            } else if (this.currentToolSelected === "linkedin-tool") {
                query = {...query, email_discover: query["discover_email"]};
                if (query.discovery) {
                    query.discovery = "discovery";
                }
            } else if (this.currentToolSelected === "facebook-tool") {
                if (query.country_code) {
                    return query.country_with_code.split("(")[1].split(")")[0] + query?.number;
                }
            } else if (this.currentToolSelected === "vlex-tool") {
                return `${query?.query}`;
            }
            let name = Object.keys(query).filter((n) => n == "name");
            if (name) {
                query = {name: query["name"], ...query};
            }
            return Object.entries(query)
                .map((n) => (n[0] != "page_size" && n[0] != "discover_email" && n[0] != "top_match" && n[0] != "start" ? n[1] : ""))
                .filter((n) => n)
                .join(", ");
        },
        savedQuery(queryId) {
            const savedQueries = this.$store?.getters?.getSavedQueiriesList;
            if (savedQueries && Array.isArray(savedQueries)) {
                return savedQueries.some(query => query.osint_request_id === queryId);  // or query.osint_request_id depending on your data structure
            }
            return false;  
        },

        getSavedQuery(queryId) {
            const savedQueries = this.$store?.getters?.getSavedQueiriesList;
            const query = savedQueries.find(query => query.osint_request_id === queryId);
            this.savebutton = false;
            if (query) {
                this.queryName = query.name; 
                this.queryData = JSON.stringify(query.query, null, 0);  
                this.queryId = query.id
                this.$refs["save-prebuild-query"].showModal();
            } else {
                console.log("Query not found");
                this.queryName = "";
                this.queryData = {};
            }
        },
        handleMonitoringStatus(query) {
            if (!this.disabled) {
                query.is_monitoring = !query.is_monitoring;
                this.$forceUpdate();
                this.$emit("changeMonitoringStatus", query);
            }
        },
        handleSettings(event) {
            event.stopPropagation();
        },

        getFrequency(frequency) {
            let days = parseInt(frequency, 10) || 0;
            return `${days} days`;
        },

        showOptionsBgLayer() {
            this.isOpen = true;
            const optionsbgLayer = document.getElementById("optionsbgLayer").cloneNode();
            const bgCopy = document.body.appendChild(optionsbgLayer);
            bgCopy.classList = "bg-layer fixed inset-0 bg-black opacity-30 z-50";
            bgCopy.id = "options-bg-copy";
            bgCopy.style.zIndex = " 590";
            this.$refs[`options-widget-${this.treeData._id}`][0]?.initiateOptionsWidget();
        },
        hideOptionsBgLayer() {
            this.isOpen = false;
            const bgCopy = document.getElementById("options-bg-copy");
            bgCopy.remove();
            this.$refs[`options-widget-${this.treeData._id}`][0]?.resetOptionsWidget();
            return true;
        },

        updateFrequency(query, isQuery) {
            this.$emit("updateFrequency", {
                query: query,
                isQuery: isQuery,
                maskedQuery: this.viewQuery(query),
            });
        },

        updateMonitoring(data) {
            this.$emit("updateMonitoring", data);
        },

        async handleRefreshQueries() {
            this.queriesLoading = true;
            await this.getToolsStatus();
            await this.getQueriesMonitoring();
            const payload = {
                tool: this.$route.query.check_name || this.autorun?.headers['x-tool-name'],
            }
            await this.getQueryMonitoringEvents(payload)
            this.queriesLoading = false;
            this.$emit("refreshQueries");
        },

        getQueryStyle(query) {
                if (query.events) return "width: calc(100% - 100px)";
                else return "width: calc(100% - 4px)"
        },
        onFilterClick() {
            this.$modal.show("tools-filter", {currentToolSelected: this.currentToolSelected});
        },
        selectAllQueries(run_query) {
            const allQueries = cloneDeep(this.searchedQueries).filter((query) => query.tool != this.currentToolSelected);
            let queries = cloneDeep(this.filteredQueries);
            queries = queries.map((query) => ({
                ...query, 
                run_query: !run_query
            }));
            this.SET_SEARCHED_QUERIES([...allQueries, ...queries]);
        },
        handleChange({target}, query_id) {
            const queryIndex = cloneDeep(this.searchedQueries).findIndex((query) => query._id == query_id);
            if (queryIndex !== -1) {
                let allQueries = cloneDeep(this.searchedQueries);
                allQueries[queryIndex]["run_query"] = target.checked;
                this.SET_SEARCHED_QUERIES(allQueries);
            }
        },
        onRunQueries() {
            const queries = this.filteredQueries.filter((query) => query.run_query).map((query) => query._id);
            this.selectedQueries = queries;
            this.$emit("onRunQueries", {
                case_id: this.getNeoCaseId,
                tool: this.currentToolSelected,
                queries,
            });
            this.SET_SELECTED_TOOL_QUERY(null);
        },
        
        
        saveQueryData(){
            let payload; 
            payload ={
                "name": this.queryName,
                "query": JSON.parse(this.queryData),
                "osint_request_id": this.osintRequestId
            }
            // payload.query = payload.query.replace(/\\"/g, '"'); 
            this.$emit("saveSearchQuery", payload);
            this.closePrebuildQuery();
        },
        closePrebuildQuery(){
            this.queryName = '';
            this.queryData  = {}
            this.$refs["save-prebuild-query"].hideModal();

        },
        saveQuery(query){
            this.$refs["save-prebuild-query"].showModal();
            this.queryName = ''
            this.queryData  = {}
            const data = query.query;
            const newData = { ...data }; // Create a shallow copy of `data`
                delete newData.sources;
            if(query.tool == 'company-name'|| query.tool == 'orbis-tool'){
                this.formattedQueryData(newData, query.tool)
            }
            else if(query.tool == 'lexis-bridger-tool')
            {
                let result = this.extractObjectAtoB(newData)
                this.formattedQueryData(result, query.tool)
            }
            this.osintRequestId = query._id
        },
        formattedQueryData(data, tool)
        {
            const cleanObject = this.cleanObject(data)
            let newObj = {};

            Object.keys(cleanObject).forEach(key => {
                newObj[key.toLowerCase()] = cleanObject[key];
            });
            const result = Object.fromEntries(
                Object.entries(newObj).filter(([key, value]) => key !== 'sources' && value && (Array.isArray(value) ? value.length > 0 : true))
            );
            const structureQuery = Object.fromEntries(
                Object.entries(PRE_BUILD_QUERY).filter(([key]) => key in result)
            );
            for (let key in result) {
                if (!(key in structureQuery)) {
                    structureQuery[key] = result[key];
                }
            }

            if (Object.hasOwn(structureQuery, 'sources')) {
                delete structureQuery.sources;
            }
            if(tool === "company-name" || tool == 'orbis-tool')
            {
                if (Object.hasOwn(structureQuery, 'officer')) {
                    structureQuery.name = structureQuery.officer;
                    delete structureQuery.officer;
                }
                this.queryData = JSON.stringify(structureQuery, null, 0);  
            }
            else if(tool === 'lexis-bridger-tool'){
                const convertData = this.convertData(structureQuery)
                this.queryData = JSON.stringify(convertData, null, 0);  
            }
        },
        extractObjectAtoB(a) {
            let b = {};
            
            if(a.company_entity){
                b.name = a.company_entity.name
            }

            if (a.person_entity) {
                b.first_name = a.person_entity.first_name;
                b.last_name = a.person_entity.last_name;
                b.middle_name = a.person_entity.middle_name;
                b.full_name = a.person_entity.full_name;
            }

            b.predefinedsearchname = a.predefinedsearchname;
            b.entity_type = a.entity_type

            if (a.additional_info) {
                const dobInfo = a.additional_info.find(item => item.type === 'DOB');
                if (dobInfo && dobInfo.date) {
                    b.dob = dobInfo.date;
                }
            }

            const citizenshipInfo = a.additional_info.find(item => item.type === 'Citizenship');
            if (citizenshipInfo) {
                b.Citizenship = citizenshipInfo.value;
            }

            // Extract IDs (SSN, National, DUNS)
            if (a.ids) {
                a.ids.forEach(id => {
                if (id.type === 'SSN') b.SSN = id.number;
                if (id.type === 'National') b.National = id.number;
                if (id.type === 'DUNS') b.DUNS = id.number;
                if (id.type == 'TaxID') 
                {
                    b.ids = a.ids.map(id => {
                        let idObj = {
                            type: id.type,
                            number: id.number
                        };

                        // Handle TaxID specific fields
                        if (id.type === 'TaxID') {
                            idObj.issuer = id.issuer;
                            idObj.date_issued = id.date_issued;
                            idObj.date_expires = id.date_expires;
                        }
                        return idObj;
                    });
                            }
                            
                });
                }
            if (a.addresses) {
                b.addresses = a.addresses.map(address => ({
                type: address.type,
                street1: address.street1,
                street2: address.street2,
                city: address.city,
                stateprovincedistrict: address.stateprovincedistrict,
                postalcode: address.postalcode,
                country: address.country
                }));
            }

            if (a.phones) {
                a.phones.forEach(phone => {
                if (phone.type === 'Business') b.Business = phone.number;
                if (phone.type === 'Home') b.Home = phone.number;
                if (phone.type === 'Work') b.Work = phone.number;
                if (phone.type === 'Cell') b.Cell = phone.number;
                if (phone.type === 'Fax') b.Fax = phone.number;
                if (phone.type === 'Unknown') b.Unknown = phone.number;
                });
            }

            return b;
        },
        convertData(input) {
            let result = {};

            // Mapping person_entity
            if (input.first_name || input.last_name || input.middle_name || input.full_name) {
                result.person_entity = {};
                if (input.first_name) result.person_entity.first_name = input.first_name;
                if (input.last_name) result.person_entity.last_name = input.last_name;
                if (input.middle_name) result.person_entity.middle_name = input.middle_name;
                if (input.full_name) result.person_entity.full_name = input.full_name;
            }

            // Mapping company_entities
            if (input.name) {
                result.company_entity = { name: input.name };
            }

            // Mapping predefinedsearchname
            if (input.predefinedsearchname) {
                result.predefinedsearchname = input.predefinedsearchname;
            }

            if(input.entity_type){
                result.entity_type = input.entity_type
            }
            // Mapping additional_info for DOB and Citizenship
            if (input.dob || input.citizenship) {
                result.additional_info = [];
                if (input.dob) result.additional_info.push({ type: "DOB", date: input.dob });
                if (input.citizenship) result.additional_info.push({ type: "Citizenship", value: input.citizenship });
            }

            // Mapping ids (SSN, National, TaxID)
            if (input.ssn || input.national || input.duns || (input.ids && input.ids.length > 0)) {
                result.ids = [];
                if (input.ssn) result.ids.push({ type: "SSN", number: input.ssn });
                if (input.national) result.ids.push({ type: "National", number: input.national });
                if (input.duns) result.ids.push({ type: "DUNS", number: input.duns });
                if (input.ids && Array.isArray(input.ids)) {
                    input.ids.forEach(id => {
                        result.ids.push({
                        type: id.type,
                        number: id.number,
                        issuer: id.issuer,
                        date_issued: id.date_issued,
                        date_expires: id.date_expires
                        });
                    });
                }
            }

            // Mapping addresses
            if (input.addresses && input.addresses.length > 0) {
                result.addresses = input.addresses.map(address => ({
                type: address.type,
                street1: address.street1,
                street2: address.street2,
                city: address.city,
                stateprovincedistrict: address.stateprovincedistrict,
                postalcode: address.postalcode,
                country: address.country,
                }));
            }

            // Mapping phones
            if (input.cell || input.business) {
                result.phones = [];
                if (input.cell) result.phones.push({ type: "Cell", number: input.cell });
                if (input.business) result.phones.push({ type: "Business", number: input.business });
                if (input.business) result.phones.push({ type: "Home", number: input.home });
                if (input.business) result.phones.push({ type: "Work", number: input.work });
                if (input.business) result.phones.push({ type: "Fax", number: input.fax });
                if (input.business) result.phones.push({ type: "Unknown", number: input.unknown });
            }

            // Removing any empty arrays or null values from the result
            for (const key in result) {
                if (Array.isArray(result[key]) && result[key].length === 0) {
                delete result[key];  // Remove empty arrays
                }
                if (result[key] === null || result[key] === undefined || (Array.isArray(result[key]) && result[key].length === 0)) {
                delete result[key];  // Remove null or undefined values
                }
            }

            return result;
        },
        cleanObject(obj) {
            return Object.fromEntries(
                Object.entries(obj)
                .filter(([_, value]) => {
                    console.log("_ ", _)
                    if (value === null || value === "") return false;

                    if (Array.isArray(value)) {

                        value = value.filter(item => {
                            if (typeof item === 'object') {
                                item = this.cleanObject(item); 
                            }
                                return Object.keys(item).length > 0; 
                            });           
                        return value.length > 0;
                    }

                    return true; 
                })
                .map(([key, value]) => {
                    if (typeof value === "object" && value !== null && !Array.isArray(value)) {
                    value = this.cleanObject(value); 
                    }
                    return [key, value]; 
                })
            );
        },
        async updateQueryData(){
            let payload;
            if(this.isValidJson){
                payload ={
                    "name": this.queryName,
                    "query": JSON.parse(this.queryData),
                    "osint_request_id": this.osintRequestId,
                }
                this.$emit("updateQueryData", payload, this.queryId);
            }
            else{
                this.$toast.error("Not a valid Json")
            }
        }
    },
};
</script>

<style lang="scss" scoped>
.search-queries {
    .search-queries-header {
        z-index: 1 !important;
    }
    // width: 18%;
    // flex: 0 0 16%;
    height: calc(100% - 132px);
    overflow-y: auto;
    &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }
    &::-webkit-scrollbar-track {
        background: transparent;
    }
    &::-webkit-scrollbar-thumb {
        background: #a8b5c9;
        border-radius: 20rem;
    }
    &::-webkit-scrollbar-corner {
        background: transparent;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: #9cadc6;
    }
}
::v-deep {
    .loader {
        width: 16px !important;
        height: 16px !important;
    }

    .toggle-switch {
        @apply h-3;
        @apply w-7;
    }

    .toggle-view {
        @apply h-2;
        @apply w-2;
        @apply ml-1;
    }
}

.box-size-initial {
    box-sizing: initial !important;
}

.spinner-4 {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    padding: 1px;
    margin-right: 1rem;
    background: conic-gradient(#0000 10%, #0d69d5) content-box;
    -webkit-mask: repeating-conic-gradient(#0000 0deg, #000 1deg 20deg, #0000 21deg 36deg), radial-gradient(farthest-side, #0000 calc(100% - 9px), #000 calc(100% - 8px));
    -webkit-mask-composite: destination-in;
    mask-composite: intersect;
    animation: s4 1s infinite steps(10);
}
@keyframes s4 {
    to {
        transform: rotate(1turn);
    }
}
</style>
